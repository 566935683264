<template>
  <div class="home">
    <h1>Qweet</h1>
  </div>
</template>

<script>


export default {
  name: 'Home',
}
</script>
